import React from "react";

const CentralAmerica = () => {

    return (
        <>
            <div className="studymainim">
                <h1 className="mbbstitle">MBBS in Central America</h1>
            </div>

            <div className="container">
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study MBBS in Central America
                    </h1>
                    <p className="studyintxt">
                        If you are looking for a new medical school, you may be wondering where the best places are to study MBBS in Central America. The universities here have excellent academic standards and the MBBS curriculum is based on the latest scientific research. In addition, the admission requirements are not as strict as those of American institutions, which means that you should not face many obstacles.
                    </p>
                    <p className="studyintxt">
                        A country in Central America with an increasing number of clinical wannabes, Belize is one of those destinations. The confirmation process is simple and basic, and the course does not require you to take a clinical examination. In addition, the universities are focusing on developing their international presentation and providing first-rate education. If you are a medical student, Central America is a great option. Just make sure to check out these locations before you apply!
                    </p>
                </div>

                <div className="universities-section">
                    <h2 className="universities-title">
                        MBBS universities in Central America
                    </h2>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> The Central America Health Sciences University,</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Belize Medical College</h6></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img className="universitiesmain-img" src="/assets/images/295429.png" alt="no-image" />
                        </div>
                    </div>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        MBBS in Central America fees
                    </h1>
                    <p className="studyintxt">
                        MBBS in Central America has become an increasingly popular study choice for students from India and other countries in the world. The country is considered a developed nation, and medical institutions there are well-known for offering high-quality education. Most MBBS universities in Central America are accredited by the World Medical Association and the Medical Council of India. In addition to being accredited by these organizations, MBBS in Central America is relatively cheap compared to many states in the USA.
                    </p>
                    <p className="studyintxt">
                        The average cost of an MBBS degree in Belize is between twenty-five lakhs.
                    </p>
                    <p className="studyintxt">
                        The tuition fee for MBBS is very low in Central America. It depends on the country and the level of education offered in that country. Thousands of students from different countries are aspiring to study medicine in Central America. The MBBS course is designed according to the latest academic trends and curriculum. Fees for Central American MBBS colleges are low, but the criteria for admission are not light.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Advantages of studying MBBS in Central America
                    </h1>
                    <p className="studyintxt">
                        The advantages of studying MBBS in Central America are numerous. It is a developing country, and the infrastructure and medical curriculum is still in their infancy. Besides, most students will not have adequate English language skills. There is also the issue of the FMGE exam after completing your MBBS, which many Central Americans find difficult to pass. Hence, it is important to prepare for this exam prior to beginning your MBBS studies in Central America.
                    </p>
                    <p className="studyintxt">
                        Students can enjoy a lower cost of living. In addition, the quality of life is quite good. You can participate in internships and research opportunities. In the future, the world’s population is expected to grow, and the demand for doctors will only increase. Studying MBBS in Central America can give you a wide range of career options. You can either work in a Central American hospital or clinic or go abroad to work in high-demand countries.
                    </p>
                    <p className="studyintxt">
                        Aside from the excellent education system, there are several advantages of studying MBBS in Central America. You will gain international exposure, earn scholarship money and gain great value. Furthermore, studying MBBS in Central America will not require you to learn a new language – most Central Americans speak English fluently. This is an advantage that many international students may not be aware of. If you’re not comfortable with the language barrier, the Central American medical curriculum is the best for Southeast Asia.
                    </p>
                </div>
                
            </div>
        </>
    )
}

export default CentralAmerica;