import React, { useEffect } from "react";
import { NavLink, componentDidMount } from "react-router-dom";

const Header = () => {


    useEffect(() => {
        const script = document.createElement("script");
        script.src = "./assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };

    }, []);

    return (
        <>
            <header className="container">
                <nav className="navbar navbar-expand-lg">
                    <NavLink className="navbar-brand" to={'/'}><img src="/assets/images/logo.png" className="headlogo" alt="no-image" /></NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#aadya" aria-controls="aadya" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fas fa-bars" style={{ color: '#0a528d' }} />
                    </button>
                    <div className="collapse navbar-collapse" id="aadya">
                        <ul className="navbar-nav mx-auto custom-header">
                            <li className="nav-item">
                                <NavLink className="nav-link active" to={'/'}>Home</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <div className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    MBBS in Abroad
                                </div>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><NavLink className="dropdown-item" to={'/MBBSinCentralAmerica'}>MBBS in Central America</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/MBBSinChina'}>MBBS in China</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/MBBSinGeorgia'}>MBBS in Georgia</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/MBBSinKazakhstan'}>MBBS in Kazakhstan</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/MBBSinPhilippines'}>MBBS in Philippines</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/MBBSinRussia'}>MBBS in Russia</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <div className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Masters in Abroad
                                </div>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><NavLink className="dropdown-item" to={'/StudyinUK'}>Study in UK</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/StudyinCanada'}>Study in Canada</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/StudyinUSA'}>Study in USA</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/StudyinAustralia'}>Study in Australia</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/StudyinSingapore'}>Study in Singapore</NavLink></li>
                                </ul>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink className="nav-link" to={'/Coursess'}>Coursess</NavLink>
                            </li> */}
                            <li className="nav-item">
                                <NavLink className="nav-link" to={'/ContactUs'}>Contact Us</NavLink>
                            </li>
                        </ul>
                        <form className="form-inline my-2 my-lg-0">
                            <ul className="contact-lis">
                                <li>
                                    <NavLink to={'tel:+919390060345'} style={{ textDecoration: 'none' }}>
                                        <h4 className="contact-number contact-number2"><span><img className="contactnumber-icon" src="/assets/images/phone-5978.svg" alt="no-image" /></span> +91 9390060345</h4>
                                    </NavLink>
                                </li>
                            </ul>
                        </form>
                    </div>
                    <div id="progress-barss"></div>
                </nav>
            </header>

        </>
    )
}
export default Header;