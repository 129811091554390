import React from "react";

const StudyInUK = () => {
    return (
        <>
            <div className="studymainim studymainim2">
                <h1 className="mbbstitle">Study in UK</h1>
            </div>

            <div className="container">
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in UK
                    </h1>
                    <p className="studyintxt">
                        The United Kingdom is an incredible study destination with many top universities and programs. In fact, four of the UK’s universities rank among the world’s top six. There are more than 50,000 undergraduate courses offered at British universities. There are many benefits to studying in the UK.
                    </p>
                    <p className="studyintxt">
                        Studying in the UK is that it’s a multicultural society, meaning people of different religions and cultures are welcome and respected. Many students from around the world choose to study in the United Kingdom, where you’ll find a tolerant, welcoming atmosphere, great weather, and amazing employment opportunities. Another perk is that UK degrees take a fraction of the time it takes in other countries. In comparison, other countries require four years to complete an undergraduate degree, and two or three years to earn a postgraduate degree. This translates into a substantial savings in education costs and doesn’t compromise on quality of teaching.
                    </p>
                </div>

                <div className="universities-section">
                    <h2 className="universities-title">
                        Best Universities in UK
                    </h2>
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Oxford University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Cambridge</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Imperial College London</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University College London</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Edinburgh</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Manchester</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> King’s College London</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Warwick</h6></li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Bristol</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of St. Andrews</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Manchester</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> King’s College London</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Warwick</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Bristol</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of St. Andrews</h6></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in UK for Indian students
                    </h1>
                    <p className="studyintxt">
                        There are many benefits of studying in UK for an Indian student. To study in the UK, you should know the rules of admission. In addition to being considered for free education, the UK also provides ample benefits to students. The education system in the UK is on par with any other country in the world. There are many courses and degrees you can choose from, starting from infant to postgraduate studies.
                    </p>
                    <p className="studyintxt">
                        In the UK, you can choose from various courses at world-renowned universities. The degree you earn from a UK university is internationally recognized. Unlike in India, your degree will have greater value in the UK’s job market. Furthermore, the language barrier between UK and Indian students may present a challenge. While English is the preferred language, you will be able to interact with your future employers in the English-speaking community.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in UK with a scholarship
                    </h1>
                    <p className="studyintxt">
                        The UK government awards many scholarships to international students to help them cover the cost of studying at a university in the country. In addition to government awards, universities also offer their own scholarships to international students. However, scholarships for post-graduate and doctoral degrees are highly competitive and do not apply to all applicants. The selection of the program and university you wish to study at is essential. There are many different types of scholarships available for international students in the UK, but the most important thing is that you pick the right one for your particular circumstances.
                    </p>
                    <p className="studyintxt">
                        A UK scholarship is a fantastic way to study in one of the world’s most diverse countries. There are more than three hundred universities in the country, and the quality of education is unmatched by any other country. It is also possible to stay in the country after graduation to find employment opportunities and work post-study. Studying in the UK is a great opportunity for both the individual and the economy. It is a rewarding experience and can lead to lifelong friendships.
                    </p>
                    <p className="studyintxt">
                        After completing your application, you must wait for your decision. Some courses require a letter of recommendation from a college or university, and you will need to upload it to the UCAS portal. After that, you will have to take an entrance exam and interview. Once you have passed these tests, you will be issued with an invitation letter and the first-year fee for your studies in the UK. The UK government also provides a number of scholarships for international students studying at universities.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Why study in UK
                    </h1>
                    <p className="studyintxt">
                        There are many reasons to study in the UK, including the excellent education and multicultural environment. In addition to the cost, British universities tend to be more affordable than those in the United States. The academic benefits of a UK education are arguably the greatest attraction. UK degrees are recognized worldwide, boosting your chances of a better job and higher salary. So, why study in the UK? Read on to learn more about the benefits of studying in the UK.
                    </p>
                    <p className="studyintxt">
                        International students love the UK for its international reputation. UK universities are internationally recognized and are attracting more international students every year. You’ll be surrounded by like-minded people from all over the world. And because it’s a safe, friendly country to study in, you won’t face any language barriers while in your studies. The quality of UK higher education is also second to none. You’ll find that research at UK universities is consistently rated among the world’s best.
                    </p>
                    <p className="studyintxt">
                        In addition to academic excellence, the UK’s educational system is shorter than most other countries. Most undergraduate programmes last three years, while postgraduate programs typically last a year. Postgraduates can complete their degrees in as little as two years, depending on the course. In addition to short degree durations, UK students can also enjoy financial benefits. In addition to lower tuition costs, the UK’s shorter academic programs also reduce student living expenses.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Advantages of studying in UK
                    </h1>
                    <p className="studyintxt">
                        For those studying in an English-speaking country, there are many advantages to studying in the UK. You will have access to a large number of opportunities, including the rich culture of the country. There is also an enormous amount of commerce, including in the capital London, and a wide variety of nightlife, including pubs and open-air markets. It’s almost impossible to get bored in the UK, and you’ll never be short of things to do or places to go.
                    </p>
                    <p className="studyintxt">
                        As one of the most sought-after educational destinations, the United Kingdom has thousands of courses to choose from. Its education system is world-renowned and British degrees are recognized worldwide. The culture is diverse and attracts a wide variety of students. A UK education is not only beneficial for a student’s future career, but for the rest of their lives as well. So what are the advantages of studying in the UK? Here are a few.
                    </p>
                    <p className="studyintxt">
                        The UK is known for its education system, which encourages critical thinking and questioning conventional knowledge. In fact, British scientists have won nearly a hundred Nobel Prizes, an accomplishment few countries can boast of. Living expenses in the UK are some of the highest among destinations for international students, so students from less developed countries may find this a deterrent.
                    </p>
                    <p className="studyintxt">
                        Free health care is another major advantage of studying in the UK. The NHS provides health care services for international students free of charge. In addition to free health care, international students can also work while they study. Food and entertainment costs are cheaper in the UK than in other countries. Rent and food costs are also lower than in the US and Canada. The economic climate in the UK means that there is no shortage of jobs for international students. However, working is not always possible while studying abroad.
                    </p>
                </div>
            </div>
        </>
    )
}

export default StudyInUK;