import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {

    return (
        <>
            <div className="footer-section">
                {/* <img className="footerlogo" src="/assets/images/logo.png" alt="no-image" /> */}
                <div className="row">
                    <div className="col-md-3">
                        <div className="footer-se">
                            <h6 className="footer-head">Address</h6>
                            <ul style={{ listStyle: 'none', paddingLeft: '0px', }}>
                                <li><span><img className="contactusim" src="/assets/images/location-pin-5809.svg" alt="no-image" /></span> 202, 2nd floor, opposite rayalaseema college, bairagipatteda, tirupati 517501.</li>
                                <li><span><img className="contactusim" src="/assets/images/mail-5928.svg" alt="no-image" /></span> info@aadyaoverseas.in</li>
                                <li><span><img className="contactusim" src="/assets/images/phone-5978.svg" alt="no-image" /></span> +91 9390060345</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer-se">
                            <h6 className="footer-head">MBBS in Abroad</h6>
                            <ul style={{ listStyle: 'none', paddingLeft: '0px', }}>
                                <li><Link className="footerlin" to={"/MBBSinCentralAmerica"}>MBBS in Central America</Link></li>
                                <li><Link className="footerlin" to={"/MBBSinChina"}>MBBS in China</Link></li>
                                <li><Link className="footerlin" to={"/MBBSinGeorgia"}>MBBS in Georgia</Link></li>
                                <li><Link className="footerlin" to={"/MBBSinKazakhstan"}>MBBS in Kazakhstan</Link></li>
                                <li><Link className="footerlin" to={"/MBBSinPhilippines"}>MBBS in Philippines</Link></li>
                                <li><Link className="footerlin" to={"/MBBSinRussia"}>MBBS in Russia</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer-se">
                            <h6 className="footer-head">Masters in Abroad</h6>
                            <ul style={{ listStyle: 'none', paddingLeft: '0px', }}>
                                <li><NavLink className="footerlin" to={'/StudyinUK'}>Study in UK</NavLink></li>
                                <li><NavLink className="footerlin" to={'/StudyinCanada'}>Study in Canada</NavLink></li>
                                <li><NavLink className="footerlin" to={'/StudyinUSA'}>Study in USA</NavLink></li>
                                <li><NavLink className="footerlin" to={'/StudyinAustralia'}>Study in Australia</NavLink></li>
                                <li><NavLink className="footerlin" to={'/StudyinSingapore'}>Study in Singapore</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer-se">
                            <h6 className="footer-head">Conditions</h6>
                            <ul style={{ listStyle: 'none', paddingLeft: '0px', }}>
                                <li><NavLink className="footerlin" to={'/PrivacyPolicy'}>Privacy Policy</NavLink></li>
                                <li><NavLink className="footerlin" to={'/TremsofUse'}>Trems of Use</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer-se">
                            <h6 className="footer-head">Follow Us</h6>
                            <ul className="socila-main">
                                <li><NavLink to={"41"}><i class="fab fa-facebook" aria-hidden="true"></i></NavLink></li>
                                <li><NavLink to={"41"}><i class="fab fa-twitter" aria-hidden="true"></i></NavLink></li>
                                <li><NavLink to={"41"}><i class="fab fa-google-plus-g" aria-hidden="true"></i></NavLink></li>
                                <li><NavLink to={"41"}><i class="fab fa-linkedin" aria-hidden="true"></i></NavLink></li>
                                <li><NavLink to={"41"}><i class="fab fa-instagram" aria-hidden="true"></i></NavLink></li>
                            </ul>
                        </div>
                        <div className="footer-se">
                            <h6 className="footer-head mt-2">Get Directions</h6>
                            <iframe className="footermap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15510.396422077058!2d79.4223841!3d13.6212794!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4d4b142532384f%3A0x5298d4d53113d812!2sAadya%20Overseas!5e0!3m2!1sen!2sin!4v1686303034999!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;