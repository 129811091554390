import React from "react";

const Russia = () => {
    return (
        <>
            <div className="studymainim">
                <h1 className="mbbstitle">MBBS in Russia</h1>
            </div>

            <div className="container">
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study MBBS in Russia
                    </h1>
                    <p className="studyintxt">
                        Russian medical universities are internationally recognized. MBBS students are eligible to take the MCI screening test if they complete their course in Russia. Unlike in India, the cost of tuition for an MBBS course in Russia is not high. Student living expenses are also similar to those of their counterparts. Since the Russian ruble is a comparable currency to the Indian Rupee, the cost of studying MBBS in Russia is within the budget of a common student.
                    </p>
                    <p className="studyintxt">
                        Among the many benefits of studying MBBS in Russia, the literacy rate is high, at 99.6%. Apart from that, students have many opportunities for extracurricular activities. There are various sports and outdoor and indoor games that are a part of the curriculum in Russia. They can also participate in tournaments and sports. Additionally, MBBS certification opens up a number of job opportunities in Russia. The Russian medical system has a high percentage of literate individuals, making it an ideal destination for a healthcare education.
                    </p>
                </div>

                <div className="universities-section">
                    <h2 className="universities-title">
                        MBBS universities in Philippines
                    </h2>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Moscow State Medical University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Bashkir State Medical University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Volgograd State Medical University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Crimea State Medical University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Orel State University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Belgorod State University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Saint Petersburg State Medical University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Orenburg State Medical University</h6></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img className="universitiesmain-img" src="/assets/images/295429.png" alt="no-image" />
                        </div>
                    </div>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        MBBS in Russia fees
                    </h1>
                    <p className="studyintxt">
                        Studying MBBS in Russia is a great choice for international students. It is relatively cheap, especially compared to other countries. MBBS in Russia fees are extremely affordable compared to the average costs for medical education in India. International students will benefit from the top-notch education and training they will receive in the country. Students will be compensated with free medical insurance as well as a high standard of campus life. Moreover, studying in Russia is an excellent investment for a young person’s future because the country is renowned for its quality education and state-of-the-art infrastructure.
                    </p>
                    <p className="studyintxt">
                        MBBS in Russia fees are quite reasonable and most universities offer this degree in English. Moreover, living costs in Russia are low, and the cost of medical education in Russia is inexpensive. Besides, studying in Russia is an opportunity to explore the country and save thousands of dollars. If you’re thinking of studying in Russia, you should know that it is possible to save up to half a year on your MBBS in Russia fees!
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Advantages of studying MBBS in Russia
                    </h1>
                    <p className="studyintxt">
                        The country’s climate is quite different from that of India. Despite its geographical location, Russia experiences mild weather throughout the year. The average winter temperature remains at -20 degrees Celsius, while fall temperatures remain at twenty-five degrees. The climate is comfortable enough indoors, and each house is equipped with heating facilities.
                    </p>
                    <p className="studyintxt">
                        Aside from that, they can live in luxurious accommodation in Russia. For accommodation, students can stay in hostels with three seated rooms. Some universities offer affordable and comfortable accommodation for international students.
                    </p>
                    <p className="studyintxt">
                        Studying MBBS in Russia has several benefits for international students. Apart from excellent educational facilities, students can enjoy a world-class student lifestyle and the chance to learn from reputed universities. Studying in a Russian medical school ensures a highly respected diploma that costs a fraction of its cost in other countries. And, tuition fees at Russian medical universities may vary from those in the West, but they are generally much cheaper than in the United States and Europe.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        MBBS in Russia Overview
                    </h1>
                    <p className="studyintxt">
                        If you are planning to study MBBS in Russia, here is a brief overview of the process. First of all, you should know that admission to MBBS courses in Russia is straightforward and free of entrance exams. You just have to score high marks in secondary school to be considered for admission. Besides, admission to MBBS in Russia is on a first-come-first-served basis, so you should not worry about waiting for the admission to open up.
                    </p>
                    <p className="studyintxt">
                        The cost of studying MBBS in Russia is reasonable, as the government subsidises the tuition by around 70%. The tuition fees are generally lower than in western countries, and you can choose from a variety of affordable options. Besides, many Indian students study MBBS in Russia. It’s no wonder, then, that Russian graduates have a good pass rate in FMGE, the country’s equivalent to the UK’s GMAT or the USMLE.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Russia;