import React from "react";

const StudyinUSA = () => {
    return (
        <>
            <div className="studymainim studymainim2">
                <h1 className="mbbstitle">Study in USA</h1>
            </div>

            <div className="container">
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in USA
                    </h1>
                    <p className="studyintxt">
                        If you’re thinking about going to the US to pursue your studies, you’re certainly not alone. In fact, there are many international students who study in the USA to pursue their undergraduate degrees. However, you should begin your planning early to ensure that you’ll have enough time to complete the necessary preparations and secure admission to a good university. Here are some tips to help you get started on your USA study experience. After all, it takes a couple of years to prepare and get into a top university.
                    </p>
                    <p className="studyintxt">
                        If you’re looking for a truly international experience, the USA offers a diverse array of cultural experiences. The West Coast, for example, is famous for its beaches, outdoor activities, and laid-back atmosphere. While the Midwest is home to large research universities and a diverse population, students are encouraged to mix with students from all over the world. This close relationship with professors will motivate and inspire you while you study in the USA. Additionally, your professor will be your mentor in your chosen career field.
                    </p>
                </div>

                <div className="universities-section">
                    <h2 className="universities-title">
                        Best Universities in USA
                    </h2>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Massachusetts Institute of Technology (MIT)</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Stanford University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Harvard University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> California Institute of Technology (Caltech)</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Chicago</h6></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Princeton University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Cornell University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Yale University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Columbia University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Pennsylvania</h6></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in USA for Indian students
                    </h1>
                    <p className="studyintxt">
                        Studying in USA for Indian students has many benefits. The educational system is comparatively better in the US, making it easier for Indian students to get a degree from top institutions. The country’s top universities and institutes offer programs in the arts, humanities, and the fine arts. Indian students can take up courses in history, philosophy, English, and humanities.
                    </p>
                    <p className="studyintxt">
                        The US is a huge and diverse country, which explains why it is a popular choice among international students. Those who wish to study in the USA can pursue a variety of different academic degrees, as the country boasts many different types of institutions offering diverse study options and affordability levels. However, there are certain aspects that Indian students should be aware of when considering a study abroad in the US. In order to make the most of this opportunity, students should prepare early and ensure that they get the required grades.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in USA with a scholarship
                    </h1>
                    <p className="studyintxt">
                        There are many ways to study in the US for free. You can apply for scholarships and use recommendations from trusted people to get into a top university. You can also research universities in your area and determine which ones are affordable while still providing high-quality education. Furthermore, knowing when application process starts and ends can help you plan ahead for your application requirements. You should submit your application early in order to increase your chances of admission. Remember to stay on track with your application deadline to avoid being late for your deadline.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Why study in USA
                    </h1>
                    <p className="studyintxt">
                        The United States is a melting pot of races and cultures, and studying in this country provides you with an education that is unparalleled. American universities encourage diversity and allow students to represent their own culture and heritage. The country’s educational system is also highly regarded as a model of freedom, and American universities offer this unique opportunity. You can also expect to meet a diversity of students and professors in the United States. A study abroad experience in the United States will give you valuable global exposure, and may even help you gain valuable work experience.
                    </p>
                    <p className="studyintxt">
                        In addition to the educational opportunities, the social life in the United States is second to none. You’ll experience a new culture and a different way of life, and you’ll be able to integrate into a diverse community while making new friends from different countries. You’ll have the opportunity to grow and develop your communication skills, and you’ll be able to engage with a diverse community and network with people from all over the world.
                    </p>
                    <p className="studyintxt">
                        Moreover, US universities provide financial assistance to their students, including scholarships, grants, and loans. These funds can help you pay for your studies, and can even help you find a job when you graduate. In addition, studying in the United States can reveal your unique personality. Living and working in a foreign country requires courage, a strong sense of curiosity, and a positive attitude. Often, the knowledge you gain from your education is more influential than your degree, so study in the US will expose you to the world in new ways and broaden your perspective on people and life.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Advantages of studying in USA
                    </h1>
                    <p className="studyintxt">
                        If you’re an international student considering the United States as your next destination, consider the many advantages of studying in the country. Firstly, the educational system is flexible, which gives international students the freedom to create a study plan that suits their interests. You can even finalize your major by the second year of your undergraduate degree, so you’ll have plenty of time to learn about different disciplines and explore the country’s many opportunities. Secondly, if you’re pursuing a master’s degree, you’ll be able to create a course that fits your academic goals and allows you to add your own opinions to the learning process.
                    </p>
                    <p className="studyintxt">
                        The US has many advantages to offer, including its advanced technology, vast resources, and innovative research facilities. The culture in the US is incredibly diverse, and students studying here can encounter diverse lifestyles and cultures that will enrich their thinking and outlook. Lastly, many American universities offer practical training as a requirement for graduation, giving students the opportunity to become familiar with the culture of the area they’re studying in. This means you’ll have the chance to apply for jobs in a variety of fields and gain a unique perspective.
                    </p>
                    <p className="studyintxt">
                        In addition to being more diverse, the American education system emphasizes diversity. Diversity in the US will broaden your horizons, develop your skills, and enhance your personality. In addition to being diverse, the US education system values sports. It is therefore very beneficial for aspiring sportsmen and women to study in the US. Lastly, studying in the United States allows you to take advantage of numerous scholarships available for all students.
                    </p>
                </div>
            </div>
        </>
    )
}

export default StudyinUSA;