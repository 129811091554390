import React from "react";

const StudyinCanada = () => {
    return (
        <>
            <div className="studymainim studymainim2">
                <h1 className="mbbstitle">Study in Canada</h1>
            </div>

            <div className="container">
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in Canada
                    </h1>
                    <p className="studyintxt">
                        If you’re thinking of studying in Canada, but are unsure of where to start, here are some helpful tips for international students:
                    </p>
                    <p className="studyintxt">
                        First, Canada has a rich educational heritage. Canadian universities are renowned around the world. Students can obtain a world-class education and gain practical skills with a Canadian degree. You’ll find that tuition fees are very reasonable and that most Canadian universities are accredited by international bodies. In addition to that, Canada’s industrialized economy and liveable cities make it an ideal study destination for many international students.
                    </p>
                </div>

                <div className="universities-section">
                    <h2 className="universities-title">
                        Best Universities in Canada
                    </h2>
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Toronto</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> The University of British Columbia</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Alberta</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> McGill University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Humber College</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Memorial University of Newfoundland</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> George Brown College</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Windsor</h6></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> York University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Waterloo</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Calgary</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Centennial College</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Ryerson University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> McMaster University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Dalhousie University</h6></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in Canada for Indian students
                    </h1>
                    <p className="studyintxt">
                        If you are planning to study in Canada, there are several things you should know about the process. Study permits are required for students who want to pursue vocational, academic or professional training. In addition to the study permit, you should also submit an application to the Canadian High Commission. Your application will be processed in a timely manner if you follow the right procedures. After you have completed the application, you can get an update on the status of your application.
                    </p>
                    <p className="studyintxt">
                        One of the first things that you must do if you are planning to study in Canada is to have a proper health check-up. Your health will be checked by a doctor whose practice is listed on the website of the Canadian Institute of Medical Research. You should also ensure that you have an adequate score in English language proficiency, as IELTS and TOEFL are accepted at all universities and colleges in Canada. Taking the language test is also mandatory if you plan to get a student visa.
                    </p>
                    <p className="studyintxt">
                        To apply for a study visa, you must meet the required requirements and score in the English assessment test.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in Canada with a scholarship
                    </h1>
                    <p className="studyintxt">
                        You may be wondering how to study in Canada with a scholarship. While undergraduate scholarships in Canada are rare, opportunities for graduate students abound. The Canadian government awards approximately 167 scholarships every year. Graduate students, for example, can apply for the Vania Scholarship. This program provides up to 50 thousand Canadian dollars to support their studies. To qualify for a Vania scholarship, students must demonstrate academic excellence and leadership skills. Approximately 200 students receive this scholarship each year, and 100 more are chosen through various organizations.
                    </p>
                    <p className="studyintxt">
                        To apply for a scholarship in Canada, you must be accepted to a university in Canada for the 2022/2023 academic year. Universities and departments grant scholarships based on the student’s merit, previous academic achievement, and overall strength of application. Scholarships vary in amount, duration, and eligibility criteria. Ensure that you research the institution to determine eligibility requirements and apply early. Ultimately, studying in Canada with a scholarship is an exciting experience for international students!
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Why study in Canada
                    </h1>
                    <p className="studyintxt">
                        If you’re considering a graduate degree in a field like engineering or business, studying in Canada may be an excellent choice. Canadian universities and colleges are internationally recognized for their quality education. They are also internationally competitive, attracting top professors to teach courses that are relevant to the rapidly changing Canadian economy. Canadian graduates are highly skilled, well-prepared, and well-connected, ready to launch a career at home or abroad. Why Study in Canada?
                    </p>
                    <p className="studyintxt">
                        International students can work up to 20 hours per week on campus or in certain locations. In this way, they can earn while studying, and pay for their studies. A further benefit of studying in Canada is the opportunity to work part-time or apply for a work permit after graduation. The diversity of the country’s population makes it a desirable place for students from other parts of the world. It is also home to diverse cultures and a vibrant cultural scene.
                    </p>
                    <p className="studyintxt">
                        Canada’s multicultural atmosphere is one of the reasons why students from different countries flock to the country. With over 34 million citizens, Canada’s population is diverse. Its culture is infused with the tastes and traditions of people from all walks of life. Many international students find their new friends and work opportunities in Canada through this diverse culture. And while Canada might seem far away from their home countries, they find that they feel at home in Canada.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Advantages of studying in Canada
                    </h1>
                    <p className="studyintxt">
                        There are many advantages to studying in Canada, but perhaps one of the most attractive is its reputation as a safe and friendly country. The country is consistently ranked high in the OECD Better Life Index, which measures life expectancy, disposable income, and atmospheric balance. Not only does Canada’s low crime rate make it safer than many other countries, it also offers numerous facilities that can help students enjoy a higher quality of life.
                    </p>
                    <p className="studyintxt">
                        The country is known for its high-quality education, low costs, diverse ethnicity, well-preserved tourist attractions, and vibrant culture. It is also home to some of the world’s best colleges. Regardless of the reasons for studying in Canada, the country is a wise choice. The current economic crisis may have hurt some countries, but this is not the case in Canada. There are several advantages of studying in Canada.
                    </p>
                    <p className="studyintxt">
                        Firstly, international students can enjoy the benefits of a robust education system. After completing their program, they can apply for work in Canada and gain employment without any restriction. Furthermore, Canadian citizens can enjoy free medical care, better job opportunities, and robust security. The costs of living and tuition in Canada are lower than most other countries. However, these differences depend on the school, program, and region. The cost of living and tuition can vary greatly, so you must carefully consider all of these factors before deciding to study in Canada.
                    </p>
                </div>
            </div>
        </>
    )
}

export default StudyinCanada;