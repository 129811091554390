import React from "react";

const Georgia = () => {
    return (
        <>
            <div className="studymainim">
                <h1 className="mbbstitle">MBBS in Georgia</h1>
            </div>

            <div className="container">
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study MBBS in Georgia
                    </h1>
                    <p className="studyintxt">
                        Many Indian students opt for Study MBBS in Georgia. This low-cost education is widely acclaimed and comes with excellent perks. The government of Georgia is taking steps to improve the number of foreign students admitted in Georgian medical colleges. The country focuses on the best learning environment for every student. The student-teacher ratio is one-to-twenty, which means that students can engage in high-quality interaction with their professors.
                    </p>
                    <p className="studyintxt">
                        The country has low crime rates, and over 5000 students currently study there. Students can rest assured of a safe environment, as crime rates in Georgia are low compared to other countries. Moreover, Georgia follows the European Credit Transfer System (ECTS), which means that they can take their credits from one university to another without incurring any academic loss.
                    </p>
                    <p className="studyintxt">
                        To study MBBS in Georgia, you should first choose a university. Apply for an MBBS course at two Georgian medical universities. Then, fill an application form for each school. Once you receive an offer letter, deposit the tuition fees into the university’s bank account.
                    </p>
                </div>

                <div className="universities-section">
                    <h2 className="universities-title">
                        MBBS universities in China
                    </h2>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Ivane Javakhishvili Tbilisi State University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> University of Georgia</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Batumi Shota Rustaveli State University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Tbilisi State Medical University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Georgian American University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Georgian National University SEU</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> European University Georgia</h6></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img className="universitiesmain-img" src="/assets/images/295429.png" alt="no-image" />
                        </div>
                    </div>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        MBBS in Georgia fees
                    </h1>
                    <p className="studyintxt">
                        MBBS in Georgia is one of the most affordable countries to study medicine. The cost of living in Georgia is almost three times less than in the UK and USA. Georgia medical universities also offer severe benefits for MBBS students. The state is home to one of the lowest crime rates in the world. Georgia medical universities also offer separate accommodation facilities for girls and boys. The country’s highest salaries also make studying in Georgia a worthwhile choice.
                    </p>
                    <p className="studyintxt">
                        MBBS in Georgia fees range from approximately 15 to 20 lakh US dollars per year for the course. In addition to being affordable, the nation has many positives for international students. It is an ecologically friendly country and one of the safest nations in the world. MBBS in Georgia fees are around 20k USD.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Advantages of Studying MBBS in Georgia
                    </h1>
                    <p className="studyintxt">
                        The benefits of studying MBBS in Georgia outweigh the cost of tuition, and this state offers quality education and affordable living costs. The state is also known for its high literacy rate, which is above 91%. Medical education in Georgia is of a high standard, and the colleges are well equipped with state-of-the-art equipment and machinery that help provide quality healthcare to the patients. In addition, Georgia medical schools are more affordable than other European nations, which makes it the ideal choice for aspiring physicians from all over the world.
                    </p>
                    <p className="studyintxt">
                        While studying in Georgia, students will find the tuition very affordable, and can even apply for scholarships based on their merit. The colleges will offer English medium instruction and Indian-style food for international students. The hospital infrastructure, laboratories, and technology are also top-notch. MBBS graduates can also expect to enjoy excellent career opportunities, as many hospitals in Georgia offer world-class healthcare. The benefits of studying MBBS in Georgia are numerous.
                    </p>
                    <p className="studyintxt">
                        Another great advantage of studying MBBS in Georgia is that the school has an excellent reputation, and you will be taught by outstanding faculty members. MBBS degrees from Georgia medical schools are recognized by international bodies.
                    </p>
                    <p className="studyintxt">
                        One of the best aspects of studying MBBS in Georgia is its geographical location. The state is located at the crossroads of Asia and Europe. It borders Turkey and the Black Sea on the west, Russia on the north, Armenia on the south, and Azerbaijan on the southeast. This region of Europe has a rich history and culture, and is a desirable location for international students to pursue their studies.
                    </p>
                    <p className="studyintxt">
                        MBBS courses in Georgia are internationally recognized and offer world-class research facilities. Students from India and other countries are eligible to study MBBS in Georgia. In Georgia, the tuition fee for the 6-year MBBS program is low. Tuition fees in Georgia do not exceed $8000 per year. Living expenses in Georgia are only about $2500 per year, which makes the whole course much more affordable than studying in India.
                    </p>
                    <p className="studyintxt">
                        Aside from the low cost of living, study MBBS in Georgia is an excellent option for Indian students, who may otherwise struggle with the expense of studying in a foreign country. There are many WHO-designated medical colleges in Georgia. Georgia is known for providing high quality education and a pro-cross-cultural experience for its students. And despite its low cost, Georgia is a great choice for medical students from India, as it has a comparatively low tuition.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        MBBS in Georgia overview
                    </h1>
                    <p className="studyintxt">
                        A MBBS in Georgia overview will give you an idea of what to expect from this medical program. The accredited program in Georgia is approved by the World Health Organization and UNESCO. Courses are taught and examined in English. The course structure will be based on the academic goals and interest of each student. There are many perks of studying in Georgia. Here are some of these benefits.
                    </p>
                    <p className="studyintxt">
                        First of all, Georgia is safe for Indian students. It has a high number of medical colleges and universities that offer MBBS courses. However, some major inadequacies are evident in Georgian medical schools. Those who study in Georgia should keep in mind the country’s governing body. The Georgian government aims to improve its educational system, and is committed to improving medical care. MBBS in Georgia is not a risky option.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Georgia;