import React from "react";

const StudyinSingapore = () => {
    return (
        <>
            <div className="studymainim studymainim2">
                <h1 className="mbbstitle">Study in Singapore</h1>
            </div>

            <div className="container">
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in Singapore
                    </h1>
                    <p className="studyintxt">
                        Study in Singapore is important for those who want to continue with higher studies and for those who plan to work in the country. In addition, if you want to enhance your career opportunities, you need to get a good education.
                    </p>
                    <p className="studyintxt">
                        Study in Singapore produces a result which is far more than what you would expect from a country with such an established reputation. Despite the global trends towards personalized and self-directed learning, Singapore education system doesn’t fail to surprise.
                    </p>
                </div>

                <div className="universities-section">
                    <h2 className="universities-title">
                        Best Universities in Singapore
                    </h2>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> National University of Singapore (NUS)</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Nanyang Technological University (NTU)</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Singapore Management University (SMU)</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> SIM University (UniSIM)</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> INSEAD – Singapore</h6></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> PSB Academy</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> SP Jain School of Global Management</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Nanyang Institute of Management</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Singapore Institute of Technology (SIT)</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> James Cook University – Singapore (JCU)</h6></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in Singapore for Indian students
                    </h1>
                    <p className="studyintxt">
                        Study in Singapore is a good option for Indian students who wish to pursue a higher education in Asia. Located in Southeast Asia, Singapore is a popular education hub, offering a diverse blend of arts, cuisines and culture. Its small size and cosmopolitan setting make it a desirable place for students from all over the world. If you’d like to pursue a higher education in Singapore, here are some tips to make it happen.
                    </p>
                    <p className="studyintxt">
                        One of the biggest advantages of studying in Singapore is the high standard of education. Several internationally renowned universities are located here. The country also boasts excellent infrastructure and facilities that make it a popular choice for international students. Furthermore, tuition fees are considerably lower than other popular destinations for studying abroad. The benefits of studying in Singapore are many. You’ll be studying in a modern, safe city with access to world-class amenities and cultural experiences.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study in Singapore with a scholarship
                    </h1>
                    <p className="studyintxt">
                        In Singapore, the education system is one of the best in the world, with 71% of students achieving exceptional scores. Most of the universities and colleges in Singapore are ranked among the top 50 in the world. English is widely spoken, so overcoming the language barrier will be an easy task. Even so, tuition fees in Singapore are quite high for the average student. If you’re exceptionally gifted, you may be eligible for a scholarship in Singapore.
                    </p>
                    <p className="studyintxt">
                        You can apply for a scholarship at a number of universities in Singapore, such as Chulabhorn Graduate Institute. This scholarship covers tuition fees and other academic fees, including air-fares and VISA fees. It also provides a monthly allowance and stipend, and requires you to return to Singapore every three years after graduation. To apply, you must be a Singaporean or Permanent Resident. For the MBBS-Ph.D. track, you must have a good honors degree in a field related to biomedical engineering or science. You must also have completed 2 years of preclinical training in the country.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Why study in Singapore
                    </h1>
                    <p className="studyintxt">
                        In addition to an excellent education system, students studying in Singapore can also enjoy the city’s safe environment, good weather, and diverse culture. In fact, Singapore is ranked eighth in the INSEAD Global Innovation Index. Students can also expect to get a globally recognized degree from any of the country’s thirty-plus schools. There is also zero tolerance for racism and a strict law enforcement policy, making studying in Singapore an attractive option for international students.
                    </p>
                    <p className="studyintxt">
                        The diversity of Singapore makes it an ideal place to study and live. The educational system is recognized around the world, and employers are increasingly seeking out graduates from Singapore institutions. Asia’s economic growth poses challenges of sustainability in a dynamic business environment. It demands new ideas, innovation, and relevance from future professionals. Singapore’s legal system is fast-emerging as a regional hub of law in South East Asia. Whether you are interested in law or business, Singapore has a quality education that is second to none.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Advantages of studying in Singapore
                    </h1>
                    <p className="studyintxt">
                        One of the main advantages of studying in Singapore is that there is a very low percentage of international students. This has helped to create a friendly, happy and welcoming environment for students from all over the world.
                    </p>
                    <p className="studyintxt">
                        Our study in Singapore program is designed to make it easier for students to get the most out of their education, while also giving them a chance to develop professionally and personally.
                    </p>
                    <p className="studyintxt">
                        Studying in Singapore requires deep roots. The education system is global, with over 4,000 schools in the country providing high-quality academic education. This makes it easier for students to choose their courses and locations for study. Students can also benefit from a rich culture and experience a new lifestyle with cultural activities, shopping, dining and entertainment all within an easy reach of home.”
                    </p>
                </div>
            </div>
        </>
    )
}

export default StudyinSingapore;