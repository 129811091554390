import React, { useEffect, useState } from "react";

const Coursess = () => {

    

    return (
        <>
            <div className="container">
               <h1>Coursess</h1>
            </div>
        </>
    )
}

export default Coursess;