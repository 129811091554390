// import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Header from './pages/common/Header/Header';
import ContactUs from './pages/ContactUs/ContactUs';
import Footer from './pages/common/Footer/Footer';
import Coursess from './pages/Coursess/Coursess';
import CentralAmerica from './pages/Mbbs/CentralAmerica/CentralAmerica';
import China from './pages/Mbbs/China/China';
import Georgia from './pages/Mbbs/Georgia/Georgia';
import Kazakhstan from './pages/Mbbs/Kazakhstan/Kazakhstan';
import Philippines from './pages/Mbbs/Philippines/Philippines';
import Russia from './pages/Mbbs/Russia/Russia';
import StudyInUK from './pages/Masters/StudyInUK/StudyInUK';
import StudyinCanada from './pages/Masters/StudyinCanada/StudyinCanada';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TremsofUse from './pages/TremsofUse/TremsofUse';
import StudyinUSA from './pages/Masters/StudyinUSA/StudyinUSA';
import StudyinAustralia from './pages/Masters/StudyinAustralia/StudyinAustralia';
import StudyinSingapore from './pages/Masters/StudyinSingapore/StudyinSingapore';


function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/ContactUs' element={<ContactUs />} />
        <Route path='/Coursess' element={<Coursess />} />
        <Route path='/MBBSinCentralAmerica' element={<CentralAmerica />} />
        <Route path='/MBBSinChina' element={<China />} />
        <Route path='/MBBSinGeorgia' element={<Georgia />} />
        <Route path='/MBBSinKazakhstan' element={<Kazakhstan />} />
        <Route path='/MBBSinPhilippines' element={<Philippines />} />
        <Route path='/MBBSinRussia' element={<Russia />} />
        <Route path='/StudyinUK' element={<StudyInUK />} />
        <Route path='/StudyinCanada' element={<StudyinCanada />} />
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/TremsofUse' element={<TremsofUse />} />
        <Route path='/StudyinUSA' element={<StudyinUSA />} />
        <Route path='/StudyinAustralia' element={<StudyinAustralia />} />
        <Route path='/StudyinSingapore' element={<StudyinSingapore />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
