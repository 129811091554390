import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        country: '',
        query: '',
    });
    const [isLoading, setIsLoading] = useState(false); // State variable to track loading state

    const isFormValid = () => {
        // Add your form validation logic here
        // For simplicity, we'll check if any of the required fields are empty
        return (
            formData.name.trim() !== '' &&
            formData.email.trim() !== '' &&
            formData.mobile.trim() !== '' &&
            formData.country.trim() !== '' &&
            formData.query.trim() !== ''
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!isFormValid()) {
                return;
            }
            // Set loading state to true before handling form submission
            setIsLoading(true);
            const response = await fetch('https://emailapi.aadyaoverseas.in/contact-us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            console.log(response);
            if (response.ok) {
                // Function to trigger the toast
                toast.success('Form submitted successfully!', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000, // Auto close the toast after 3000ms (3 seconds)
                });
                // Simulate an asynchronous form submission with setTimeout
                setTimeout(() => {
                    // Handle form submission logic here (e.g., API request, etc.)

                    // After form submission, reset the form and loading state
                    setFormData({
                        name: '',
                        email: '',
                        mobile: '',
                        country: '',
                        query: '',
                    });
                    setIsLoading(false);
                }, 2000);
                // Handle successful form submission (e.g., show a success message)
                // console.log('Form submitted successfully!');
            } else {
                // Handle errors (e.g., show an error message)
                console.error('Form submission failed.');
            }
        } catch (error) {
            // console.error('Error submitting form:', error);
            toast.error('Error submitting form', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000, // Auto close the toast after 3000ms (3 seconds)
            });
        }
    };

    return (
        <>
            <div className="contactus2">
                <h1 className="mbbstitle">Contact Us</h1>
            </div>
            <div className="contactus-section">
                <div className="container">
                    {/* <h1 className="contactus-head">Contact Us</h1> */}
                    <div className="row">
                        <div className="col-md-5">
                            <img className="contactusim" src="/assets/images/contactus.png" alt="no" />
                        </div>
                        <div className="col-md-7">
                            <div className="contactus-details">
                                <ul style={{ listStyle: 'none', paddingLeft: '0px', }}>
                                    <li><span><img className="contactusim" src="/assets/images/location-pin-5809.svg" alt="no" /></span>202, 2nd floor, opposite rayalaseema college, bairagipatteda, tirupati 517501.</li>
                                    <li><span><img className="contactusim" src="/assets/images/mail-5928.svg" alt="no" /></span>info@aadyaoverseas.in</li>
                                    <li><span><img className="contactusim" src="/assets/images/phone-5978.svg" alt="no" /></span>+91 9390060345</li>
                                </ul>
                            </div>
                            <div className="contactus-right">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="nameFormControlInput" className="form-label contactustitle">Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                    className="form-control contactusinnertxt"
                                                    id="nameFormControlInput"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="emailFormControlInput1" className="form-label contactustitle">Email Address</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    className="form-control contactusinnertxt"
                                                    id="emailFormControlInput1"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="nameFormControlInput" className="form-label contactustitle">Mobile Number</label>
                                                <input
                                                    type="tel"
                                                    name="mobile"
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                    className="form-control contactusinnertxt"
                                                    id="nameFormControlInput"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="queryFormControlInput1" className="form-label contactustitle">Country Interested</label>
                                                <input
                                                    type="text"
                                                    name="country"
                                                    value={formData.country}
                                                    onChange={handleChange}
                                                    className="form-control contactusinnertxt"
                                                    id="queryFormControlInput1"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="messageFormControlTextarea1" className="form-label contactustitle">Query</label>
                                                <textarea
                                                    type="text"
                                                    name="query"
                                                    value={formData.query}
                                                    onChange={handleChange}
                                                    className="form-control contactusinnertxt"
                                                    id="messageFormControlTextarea1"
                                                    rows={5}
                                                    defaultValue={""}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" disabled={isLoading || !isFormValid()} className={isFormValid() ? 'enabled-button' : 'disabled-button'}>{isLoading ? 'Submitting...' : 'Submit'}</button>
                                        <ToastContainer />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* <div className="contactus-section">
                <div className="container">
                    <h1 className="contactus-head">Get Directions</h1>
                    <div className="row">
                        <div className="col-md-5">
                            <img className="contactusim" src="/assets/images/location.png" alt="no" />
                        </div>
                        <div className="col-md-7">
                            <iframe className="contactusmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.2236797355695!2d78.3597354755854!3d17.449005001026357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91aca9fb631d%3A0xfbbbab9d59389c04!2sChakravuyha%20Software%20Technologies!5e0!3m2!1sen!2sin!4v1684912069820!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default ContactUs;