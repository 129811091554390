import React from "react";

const Kazakhstan = () => {
    return (
        <>
            <div className="studymainim">
                <h1 className="mbbstitle">MBBS in Kazakhstan</h1>
            </div>

            <div className="container">
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study MBBS in Kazakhstan
                    </h1>
                    <p className="studyintxt">
                        Whether you are a recent graduate or are interested in pursuing higher studies in a foreign country, you might want to consider studying MBBS in Kazakhstan. Here, you’ll find information on the application process, fees, and the benefits of MBBS in Kazakhstan. Moreover, you’ll learn about the NEET exam requirements. For admission, you must be at least 17 years old by the 31st December of the Admission session. In addition, you should have passed your 10+2 with science subjects and obtained a minimum 50% score from an approved board. Luckily, the admission process in Kazakhstan is easy and there are several NMC-approved colleges in the country. Some students might be concerned that the medium of instruction in Kazakhstan is not in English, but rest assured, it is.
                    </p>
                </div>

                <div className="universities-section">
                    <h2 className="universities-title">
                        MBBS universities in Kazakhstan
                    </h2>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Kazakh National Medical University.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Al-Farabi Kazakh National Medical University.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Astana Medical University.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Kokshetau State University.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Kazakh Medical University of Continuing Education.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> South Kazakhstan Medical Academy.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Karaganda State Medical University.</h6></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img className="universitiesmain-img" src="/assets/images/295429.png" alt="no-image" />
                        </div>
                    </div>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        MBBS in Kazakhstan fees
                    </h1>
                    <p className="studyintxt">
                        Studying MBBS in Kazakhstan is cheaper than pursuing the same course in an Indian medical college. The country has attracted a steady stream of Indian students for more than 30 years. The MBBS course in Kazakhstan is designed for Indian students, who find the syllabus suitable. The fees charged by medical colleges in Kazakhstan vary depending on the type of university, its location, and grade. For example, a university in Kazakhstan that offers an English-taught course will cost less than one that does not.
                    </p>
                    <p className="studyintxt">
                        The fees for studying MBBS in Kazakhstan are lower than those in other Asian countries. Most Kazakhstani medical schools are NMC accredited, and the admission procedure is straightforward and easy to understand. There is no waiting list for admission. The average fee per credit hour for an MBBS program is $24,800. The government provides adequate security to foreign students who study in Kazakhstan. In addition, the program lasts for five years.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Advantages of studying MBBS in Kazakhstan
                    </h1>
                    <p className="studyintxt">
                        The admission procedure for MBBS in Kazakhstan is simple. Moreover, Kazakhstan welcomes students from India with open arms. This is one of the safest countries for students and parents don’t have to worry about their kids’ safety. Students from different backgrounds and from different countries can benefit from the affordable tuition fees and other benefits of studying MBBS in Kazakhstan. Moreover, the country has an environment that makes life for medical students easier, so it is a perfect option for introverts and less outgoing individuals.
                    </p>
                    <p className="studyintxt">
                        One of the major benefits of studying MBBS in Kazakhstan is the cost. Private medical universities in India tend to be expensive and it is very difficult for an Asian student to afford their course. Aside from this, the price of living in Kazakhstan is much lower. Moreover, the cost of living in Kazakhstan is far lower than in most parts of Asia. If you are thinking about pursuing an MBBS course, you should consider studying in Kazakhstan.
                    </p>
                    <p className="studyintxt">
                        As a result, Kazakhstan is a comparatively cheap option for international students. The cost of living in Kazakhstan is very affordable and you can even get a work permit there after you have completed your course. Besides, you will be able to pursue your medical degree at an international standard. Kazakhstan medical schools have a reputation of being among the best in the world. Aside from that, the country’s healthcare system is world class and the students can take NMC Screening tests after completing their studies in Kazakhstan.
                    </p>
                    <p className="studyintxt">
                        The cost of education in Kazakhstan is reasonable and it is one of the best investments you can make. Living costs are low, and international students are welcomed at this university. Moreover, the admission procedure is hassle-free. There are no complicated customs arrangements. The best thing about studying MBBS in Kazakhstan is the fact that it can take up to 5 years to complete your degree. It can be quite difficult to find an affordable and high-quality medical school in India, but there are some major advantages to studying MBBS in Kazakhstan.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        MBBS in Kazakhstan overview
                    </h1>
                    <p className="studyintxt">
                        While pursuing MBBS in Kazakhstan, Indian students need to know a few important facts. The country is safe for international students. The police are strict but the crime rate is low. Many Indians live in Kazakhstan, which is one of the top 50 countries sending students there. Kazakhstan’s government ensures that all students are adequately protected. Here are some other important details about the education system in Kazakhstan:
                    </p>
                    <p className="studyintxt">
                        The cost of studying in Kazakhstan is considerably lower than in India, which has strict tuition fees and a large population. In addition, many of the medical colleges in Kazakhstan are underdeveloped and lack proper infrastructure. The medical curriculum is also unique to Kazakhstan, with most students speaking only Russian or Chinese, which may be a hindrance. As a result, many students struggle to pass the FMGE exam after finishing their study in Kazakhstan.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Kazakhstan;