import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import { EffectFade } from 'swiper';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import required modules
import { Autoplay, Pagination } from "swiper";
import { NavLink } from "react-router-dom";

const Home = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        country: '',
        query: '',
    });
    const [isLoading, setIsLoading] = useState(false); // State variable to track loading state

    const isFormValid = () => {
        // Add your form validation logic here
        // For simplicity, we'll check if any of the required fields are empty
        return (
            formData.name.trim() !== '' &&
            formData.email.trim() !== '' &&
            formData.mobile.trim() !== '' &&
            formData.country.trim() !== '' &&
            formData.query.trim() !== ''
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!isFormValid()) {
                return;
            }
            // Set loading state to true before handling form submission
            setIsLoading(true);
            const response = await fetch('https://emailapi.aadyaoverseas.in/contact-us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            console.log(response);
            if (response.ok) {
                // Function to trigger the toast
                toast.success('Form submitted successfully!', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000, // Auto close the toast after 3000ms (3 seconds)
                });
                // Simulate an asynchronous form submission with setTimeout
                setTimeout(() => {
                    // Handle form submission logic here (e.g., API request, etc.)

                    // After form submission, reset the form and loading state
                    setFormData({
                        name: '',
                        email: '',
                        mobile: '',
                        country: '',
                        query: '',
                    });
                    setIsLoading(false);
                }, 2000);
                // Handle successful form submission (e.g., show a success message)
                // console.log('Form submitted successfully!');
            } else {
                // Handle errors (e.g., show an error message)
                console.error('Form submission failed.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <>
            <div className="section1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Swiper
                                direction={"horizontal"}
                                centeredSlides={false}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 3500,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 1,
                                        spaceBetween: 50,
                                    },
                                }}
                                modules={[Autoplay, Pagination]}
                                className="mySwiper customswiper"
                                grabCursor={true}
                                freeMode={true}
                                spaceBetween={50}
                                // slidesPerView={6}
                                scrollbar={{ draggable: true }}
                                dir="ltr"
                            >
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h1 className="mainsec-header">WELCOME TO AADYA OVERSEAS</h1>
                                                <p className="mainsec-subhead">
                                                    Welcome to Aadya Overseas, the leading consultancy in Tirupati for aspiring medical students. We are here to assist you in fulfilling your dream of pursuing MBBS in top-ranked universities in Europe. We have the right guidance and support for you. Our team of experts will help you with every step of the admission process, from choosing the best university, to securing a visa and accommodation. With Aadya Overseas, you can achieve your goal of becoming a doctor with confidence and ease.
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <img className="mainsec-im" src="/assets/images/1.jpg" alt="no" />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h1 className="mainsec-header">STUDY OPPORTUNITIES ABROAD</h1>
                                                <p className="mainsec-subhead">Aadya Overseas has the perfect opportunity for you. We offer you the chance to study medicine at one of the top universities in Kyrgyzstan, a beautiful and culturally rich country in Central Asia. You can enjoy the benefits of high-quality education, international exposure, and low tuition fees. Aadya Overseas will help you with the admission process, visa application, accommodation, and other services. Don't miss this opportunity to fulfill your dream of becoming a doctor. Contact us today to know more about our programs and services.</p>

                                            </div>
                                            <div className="col-md-6">
                                                <img className="mainsec-im" src="/assets/images/2.jpg" alt="no" />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h1 className="mainsec-header">TO STUDY ABROAD ALL YOU NEED IS MONEY AND COUNSELLING</h1>
                                                <p className="mainsec-subhead">For pursuing a medical degree abroad, Kyrgyzstan is a great option. Kyrgyzstan has many reputed and accredited medical universities that offer high-quality education at affordable prices. Compared to Indian medical colleges, you can save a lot of money by studying in Kyrgyzstan. However, before you apply, you need to get proper counselling from experts who can guide you through the admission process, visa requirements, and other details. Counselling is essential to ensure that you choose the right university and course for your career goals. </p>

                                            </div>
                                            <div className="col-md-6">
                                                <img className="mainsec-im" src="/assets/images/3.jpg" alt="no" />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section2">
                <div className="container">
                    <h2 className="aboutus">About Us</h2>
                    <p className="aboutustxt">
                        Looking for a reliable and affordable Study MBBS abroad consultant in Tirupati, look no further than Aadya Overseas. We are the best choice for students who want to pursue their medical education in Kyrgyzstan and other European countries. We have a team of experienced and professional counselors who will guide you through every step of the admission process. We will help you find the best college, that suits your budget and career goals. With Aadya Overseas, you can fulfill your dream of becoming a doctor without any hassle. Contact us today and get ready to embark on a rewarding journey of learning and professional success.
                    </p>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="about-sec">
                                <img className="aboutsec-im" src="/assets/images/vision.svg" alt="no" />
                                <h6 className="aboutus-head">Our Vision</h6>
                                <p className="aboutus-subtxt">
                                    Our vision is to help students from India become doctors. We believe that every aspiring medical student deserves a chance to pursue their dream of becoming a doctor and serving their community. That is why we offer admissions to high-quality universities in Kyrgyzstan with admission guidance to help students. We are committed to helping students from India become doctors and make a positive impact on the world.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-sec">
                                <img className="aboutsec-im" src="/assets/images/Web12.svg" alt="no" />
                                <h6 className="aboutus-head">Our Goal and Mission</h6>
                                <p className="aboutus-subtxt">
                                    At Aadya overseas, we are committed to providing high-quality medical education to Indian students at affordable prices. We have partnered with SALYMBEKOV University in Kyrgyzstan to offer MBBS program with cheap tuition fees. Our goal is to help Indian students pursue their dream of becoming doctors without compromising on quality or cost. We offer guidance and support throughout the admission process, visa application, accommodation, and post-graduation opportunities. We believe that every student deserves a chance to access world-class medical education, regardless of their financial background or location.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section3">
                <div className="container">
                    <h2 className="section3-mainhead">Our Services</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="section3-sec">
                                <div className="section3sec-im">
                                    <img className="section3bg" src="/assets/images/serv001.svg" alt="no" />
                                </div>
                                <h6 className="section3-head">COUNSELLING & ADMISSION</h6>
                                <p className="section3-subtxt">
                                    Our counsellors are well experienced in the admission process, eligibility criteria, fee structure, and other aspects of studying MBBS abroad. They will help you choose the best medical university that suits your budget, preferences, and career goals. With Aadya Overseas, you can fulfill your dream of becoming a doctor without any hassle.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="section3-sec">
                                <div className="section3sec-im">
                                    <img src="/assets/images/serv002.svg" alt="no" />
                                </div>
                                <h6 className="section3-head">VISA APPLICATION </h6>
                                <p className="section3-subtxt">
                                    Kyrgyzstan & Georgia are the most popular destinations for Indian medical students who want to pursue their MBBS abroad. The countries offer a hassle-free visa process for Indian nationals, which makes it easier for them to study in the reputed medical colleges of Kyrgyzstan & Georgia. The visa requirements are simple and straightforward, and the visa fee is affordable. Indian students can apply for a student visa online or through an authorized agent, and they can expect to receive their visa within 15 days of submitting their application. Kyrgyzstan & Georgia provide a safe and friendly environment for Indian students, who can enjoy the rich culture and natural beauty of the country while pursuing their medical education.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="section3-sec">
                                <div className="section3sec-im">
                                    <img src="/assets/images/serv003.svg" alt="no" />
                                </div>
                                <h6 className="section3-head">TRAVEL ASSISTANCE</h6>
                                <p className="section3-subtxt">
                                    We understand your nervousness about travelling to a country for a long stay as a student. It is a big decision that involves leaving behind your familiar surroundings and adapting to a new culture and environment. However, studying abroad can be a rewarding and enriching experience that will broaden your horizons and enhance your academic and personal growth. That is why we are here to support you throughout your journey and provide you with the necessary information and guidance to make your transition as smooth as possible.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-4">
                            <div className="section3-sec section3-sec2">
                                <div className="section3sec-im">
                                    <img src="/assets/images/serv004.svg" alt="no" />
                                </div>
                                <h6 className="section3-head">University & course selection</h6>
                                <p className="section3-subtxt">
                                    Course selection, university ranking and reputation, location, cost of living, employability
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="section3-sec section3-sec2">
                                <div className="section3sec-im">
                                    <img src="/assets/images/serv006.svg" alt="no" />
                                </div>
                                <h6 className="section3-head">IELTS/PTE training</h6>
                                <p className="section3-subtxt">
                                    IELTS/PTE training: 18-day course taught by professional staff with class room training and mock tests
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="section3-sec section3-sec2">
                                <div className="section3sec-im">
                                    <img src="/assets/images/serv005.svg" alt="no" />
                                </div>
                                <h6 className="section3-head">Student counselling</h6>
                                <p className="section3-subtxt">
                                    Career guidance that matches your academic profile, interest, financial capability and aspirations
                                </p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="section4">
                <div className="container">
                    <h2 className="section4-mainhead">Why Choose Us?</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section4-left">
                                <div className="media">
                                    <img className="section4bg" src="/assets/images/Web123.svg" alt="no" />
                                    <div className="media-body">
                                        <h5 className="mediahead">Aadya Overseas is experienced in assisting aspiring medical students</h5>
                                        <p className="mediasubhead">
                                            We have been in the business of guiding and supporting medical students for over a decade, we have the right connections and resources to assist you getting admission into the best medical universities of Kyrgyzstan & Georgia.
                                        </p>
                                    </div>
                                </div>
                                <div className="media">
                                    <img className="section4bg" src="/assets/images/Web123.svg" alt="no" />
                                    <div className="media-body">
                                        <h5 className="mediahead">The Study MBBS Abroad Experts</h5>
                                        <p className="mediasubhead">
                                            We will assist you with every step of the process, from choosing the best University, to securing admission and visa, to settling in your new environment. With Aadya Overseas, you can be assured that you are in good & experienced hands.
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="media">
                                    <img className="section4bg" src="/assets/images/Web123.svg" alt="no" />
                                    <div className="media-body">
                                        <h5 className="mediahead">We don't stop until you're totally happy</h5>
                                        <p className="mediasubhead">
                                            We drive results that make a measurable difference. We're not satisfied until you're satisfied.
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img className="whychoseusim" src="/assets/images/whychoseus.png" alt="no" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="section5">
                <div className="container">
                    <h2 className="section5-mainhead">Why Study MBBS IN Kyrgyzstan & Georgia</h2>
                    <p className="aboutustxt">
                        Studying MBBS in Kyrgyzstan & Georgia is a great option for Indian students who want to pursue a career in medicine. Kyrgyzstan & Georgia offer high-quality education at affordable prices, with many accredited universities and colleges. Some of the benefits of studying MBBS in Kyrgyzstan & Georgia are:
                    </p>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section5-se section5-se2">
                                <div className="media">
                                    <img className="secim" src="/assets/images/Web123.svg" alt="no" />
                                    <div className="media-body">
                                        <h5 className="mediahead">Low tuition fees and living expenses</h5>
                                        <p className="mediaheadsub">
                                            Studying MBBS in Kyrgyzstan & Georgia is a great option for Indian students who want to pursue a career in medicine as the cost of living and tuition fees are very low compared to other countries, making it an economical choice for Indian students.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="section5-se section5-se2">
                                <div className="media">
                                    <img className="secim" src="/assets/images/Web123.svg" alt="no" />
                                    <div className="media-body">
                                        <h5 className="mediahead">Medical Universities offering High quality education with internationally recognized MBBS degrees</h5>
                                        <p className="mediaheadsub">
                                            The MBBS curriculum at medical universities in Kyrgyzstan & Georgia is based on the standards of the World Health Organization (WHO) and the Medical Council of India (MCI), which ensures global recognition and validity of the MBBS degree.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section5-se">
                                <div className="media">
                                    <img className="secim" src="/assets/images/Web123.svg" alt="no" />
                                    <div className="media-body">
                                        <h5 className="mediahead">Easy admission & Visa Process</h5>
                                        <p className="mediaheadsub">
                                            The admission process is simple and hassle-free, with no entrance exams or donations to be paid. The Visa process for Indian students is easy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="section5-se">
                                <div className="media">
                                    <img className="secim" src="/assets/images/Web123.svg" alt="no" />
                                    <div className="media-body">
                                        <h5 className="mediahead">Kyrgyzstan & Georgia are the best choice for MBBS students from India</h5>
                                        <p className="mediaheadsub">
                                            English is the medium of instruction for MBBS in Kyrgyzstan & Georgia which makes it easy for Indian students to communicate and learn. The people are friendly and hospitable towards Indian students
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-6">
                            <div className="section5-se section5-se2">
                                <div className="media">
                                    <img className="secim" src="/assets/images/Web123.svg" alt="no" />
                                    <div className="media-body">
                                        <h5 className="mediahead">Employability</h5>
                                        <p className="mediaheadsub">
                                            Career guidance that matches your academic profile, interest, financial capability and aspirations
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="section5-se section5-se2">
                                <div className="media">
                                    <img className="secim" src="/assets/images/Web123.svg" alt="no" />
                                    <div className="media-body">
                                        <h5 className="mediahead">Global exposure & networking</h5>
                                        <p className="mediaheadsub">
                                            Acquire multi-cultural competences and form international networks. Your language, social and interpersonal skills are guaranteed to improve.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section5-se">
                                <div className="media">
                                    <img className="secim" src="/assets/images/Web123.svg" alt="no" />
                                    <div className="media-body">
                                        <h5 className="mediahead">Return on investment</h5>
                                        <p className="mediaheadsub">
                                            Worried about paying off your education loan debt or spending your family savings? The investment is recoverable with best planning after securing employment as the currency rates in foreign countries are much stronger than that of India.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="section5-se">
                                <div className="media">
                                    <img className="secim" src="/assets/images/Web123.svg" alt="no" />
                                    <div className="media-body">
                                        <h5 className="mediahead">Immigration policies</h5>
                                        <p className="mediaheadsub">
                                            Majority of Indian students are highly-price-sensitive seeking options with low cost and high value. Various countries have introduced PSW/WPP (Post Study Work / Work Permit Programs) that allows you to gain work experience after education and qualify for permanent residency.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="section6">
                <div className="container">
                    <h2 className="section6-mainhead">Masters in Abroad</h2>
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <NavLink to={'/StudyinUK'} style={{ textDecoration: 'none' }}>
                                <div className="section6-sec">
                                    <img className="section6im" src="/assets/images/uk.jpg" alt="no" />
                                    <div className="section6-in">
                                        <h6><span><img className="section6imgas" src="/assets/images/UKflag.png" alt="no" /></span> Study in UK</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-4">
                            <NavLink to={'/StudyinCanada'} style={{ textDecoration: 'none' }}>
                                <div className="section6-sec">
                                    <img className="section6im" src="/assets/images/canadaimg.jpg" alt="no" />
                                    <div className="section6-in">
                                        <h6><span><img className="section6imgas" src="/assets/images/canada.png" alt="no" /></span> Study in Canada</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-4">
                            <NavLink to={'/StudyinUSA'} style={{ textDecoration: 'none' }}>
                                <div className="section6-sec">
                                    <img className="section6im" src="/assets/images/usaimg.jpg" alt="no" />
                                    <div className="section6-in">
                                        <h6><span><img className="section6imgas" src="/assets/images/USAflag.png" alt="no" /></span> Study in USA</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <NavLink to={'/StudyinAustralia'} style={{ textDecoration: 'none' }}>
                                <div className="section6-sec">
                                    <img className="section6im" src="/assets/images/australiaimg.jpg" alt="no" />
                                    <div className="section6-in">
                                        <h6><span><img className="section6imgas" src="/assets/images/australia.png" alt="no" /></span> Study in Australia</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-4">
                            <NavLink to={'/StudyinSingapore'} style={{ textDecoration: 'none' }}>
                                <div className="section6-sec">
                                    <img className="section6im" src="/assets/images/Singaporeimg.jpg" alt="no" />
                                    <div className="section6-in">
                                        <h6><span><img className="section6imgas" src="/assets/images/Singapore.png" alt="no" /></span> Study in Singapore</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section6">
                <div className="container">
                    <h2 className="section6-mainhead">MBBS in Abroad</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <NavLink to={'/MBBSinCentralAmerica'} style={{ textDecoration: 'none' }}>
                                <div className="section6-sec">
                                    <img className="section6im" src="/assets/images/img000.jpeg" alt="no" />
                                    <div className="section6-in">
                                        <h6><span><img className="section6imgas" src="/assets/images/3135715.png" alt="no" /></span>MBBS in Central America</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-4">
                            <NavLink to={'/MBBSinChina'} style={{ textDecoration: 'none' }}>
                                <div className="section6-sec">
                                    <img className="section6im" src="/assets/images/img111.jpeg" alt="no" />
                                    <div className="section6-in">
                                        <h6><span><img className="section6imgas" src="/assets/images/china.png" alt="no" /></span>MBBS in China</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-4">
                            <NavLink to={'/MBBSinGeorgia'} style={{ textDecoration: 'none' }}>
                                <div className="section6-sec">
                                    <img className="section6im" src="/assets/images/img222.jpeg" alt="no" />
                                    <div className="section6-in">
                                        <h6><span><img className="section6imgas" src="/assets/images/georgia.png" alt="no" /></span>MBBS in Georgia</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <NavLink to={'/MBBSinKazakhstan'} style={{ textDecoration: 'none' }}>
                                <div className="section6-sec">
                                    <img className="section6im" src="/assets/images/img333.jpeg" alt="no" />
                                    <div className="section6-in">
                                        <h6><span><img className="section6imgas" src="/assets/images/kazakhstan.png" alt="no" /></span>MBBS in Kazakhstan</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-4">
                            <NavLink to={'/MBBSinPhilippines'} style={{ textDecoration: 'none' }}>
                                <div className="section6-sec">
                                    <img className="section6im" src="/assets/images/img444.jpeg" alt="no" />
                                    <div className="section6-in">
                                        <h6><span><img className="section6imgas" src="/assets/images/philippines.png" alt="no" /></span>MBBS in Philippines</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-md-4">
                            <NavLink to={'/MBBSinRussia'} style={{ textDecoration: 'none' }}>
                                <div className="section6-sec">
                                    <img className="section6im" src="/assets/images/img555.jpeg" alt="no" />
                                    <div className="section6-in">
                                        <h6><span><img className="section6imgas" src="/assets/images/russia.png" alt="no" /></span>MBBS in Russia</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contactus-section">
                <div className="container">
                    <h1 className="contactus-head">Contact Us</h1>
                    <div className="row">
                        <div className="col-md-5">
                            <img className="contactusim contactusim2" src="/assets/images/contactus.png" alt="no" />
                        </div>
                        <div className="col-md-7">

                            <div className="contactus-right">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="nameFormControlInput" className="form-label contactustitle">Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                    className="form-control contactusinnertxt"
                                                    id="nameFormControlInput"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="emailFormControlInput1" className="form-label contactustitle">Email address</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    className="form-control contactusinnertxt"
                                                    id="emailFormControlInput1"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="nameFormControlInput" className="form-label contactustitle">Mobile Number</label>
                                                <input
                                                    type="text"
                                                    name="mobile"
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                    required
                                                    className="form-control contactusinnertxt"
                                                    id="nameFormControlInput"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="queryFormControlInput1" className="form-label contactustitle">Country Interested</label>
                                                <input
                                                    type="text"
                                                    name="country"
                                                    value={formData.country}
                                                    onChange={handleChange}
                                                    required
                                                    className="form-control contactusinnertxt"
                                                    id="queryFormControlInput1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="messageFormControlTextarea1" className="form-label contactustitle">Query</label>
                                                <textarea
                                                    type="text"
                                                    name="query"
                                                    value={formData.query}
                                                    onChange={handleChange}
                                                    required
                                                    className="form-control contactusinnertxt"
                                                    id="messageFormControlTextarea1"
                                                    rows={5}
                                                    defaultValue={""} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="text-center">
                                        <button className="contactusbt" type="submit">Submit</button>
                                    </div> */}
                                    <div className="text-center">
                                        <button type="submit" disabled={isLoading || !isFormValid()} className={isFormValid() ? 'enabled-button' : 'disabled-button'}>{isLoading ? 'Submitting...' : 'Submit'}</button>
                                        <ToastContainer />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default Home;