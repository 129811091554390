import React from "react";

const China = () => {
    return (
        <>
            <div className="studymainim">
                <h1 className="mbbstitle">MBBS in China</h1>
            </div>

            <div className="container">
                <div className="studyinsection">
                    <h1 className="studyin-title">
                    Study MBBS in China
                    </h1>
                    <p className="studyintxt">
                        If you want to study MBBS in China, there are certain things that you should keep in mind. The first thing that you should focus on is the language. It can be difficult to communicate in English, especially with the large population of Chinese natives. To solve this problem, you should try to learn the Chinese language during your first year. This will make it easier to communicate with the Chinese people. You can also try to study Chinese with the help of your classmates.
                    </p>
                    <p className="studyintxt">
                        The duration of MBBS in China is six years, including a year of internship. This training is offered in English and is approved by various dental councils and international bodies. The quality of the curriculum is equivalent to that of top medical schools in the west. In addition to its affordability, students also benefit from the fact that the Chinese government subsidizes living expenses. Therefore, if you’re a student looking for a cheaper way to study medicine, consider studying MBBS in China.
                    </p>
                </div>

                <div className="universities-section">
                    <h2 className="universities-title">
                        MBBS universities in China
                    </h2>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Peking University.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Shanghai Jiao Tong University.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Fudan University.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Sun Yat-sen University.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Central South University.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Peking Union Medical College.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Zhejiang University.</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Sichuan University.</h6></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img className="universitiesmain-img" src="/assets/images/295429.png" alt="no-image" />
                        </div>
                    </div>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        MBBS in China fees
                    </h1>
                    <p className="studyintxt">
                        Before you begin your search for a good medical university, you must know the MBBS in China fees. Tuition fees vary greatly from university to university and include a variety of pre-arrival expenses, including entrance exams, flight fares, medical insurance costs, and student visa application fees. MBBS in China fees also include the cost of living in China and personal expenses. Listed below are some of the main factors that determine your tuition fee. In addition, the cost of studying in China is extremely affordable – government colleges and bilingual universities often offer a lower-cost English-taught program.
                    </p>
                    <p className="studyintxt">
                        Chinese government and many prestigious universities offer scholarships to international students. Scholarships are awarded based on academic performance. Indian students studying in China can earn more money by practising medicine in China and can expect to receive a high-quality MBBS degree. In addition, the Chinese government supports education and ethical behaviour more than any other country. Scholarships are available for Indian students at reputable universities. You can apply for scholarships in the Chinese universities you plan to study in and continue your scholarship search once you’re in school.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Advantages of studying MBBS in China
                    </h1>
                    <p className="studyintxt">
                        While studying MBBS in China, you’ll get a very good education at a much lower cost than in other countries. The fees are cheaper here, and the course lasts five or six years. The course is taught in English, and foreign students can attend classes in any of the many recognized medical colleges. The cost of tuition is also much less than in the US or UK, and recommended university fees are around $1,000 to $2,000. Another big advantage is that you’ll be able to afford the fee, making it a viable option for many.
                    </p>
                    <p className="studyintxt">
                        MBBS in China is extremely lucrative for international students. China invests in its educational system more than any other country in the world. The course lasts six years, and it doesn’t require a TOFEL or IELTS exam. MBBS in China also offers students the added benefit of having a two-year gap between their undergraduate program and the start of their residency. Plus, you’ll get lots of hands-on experience at affiliated hospitals.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        MBBS in China overview
                    </h1>
                    <p className="studyintxt">
                        The country boasts of an ancient civilization and culture, yet combines both traditional and modern clinical medicine. Upon graduation, medical students in China are awarded the MBBS degree – Bachelor of Medicine and Bachelor of Surgery. International students can now pursue this degree in China thanks to an avenue that opened in 2002. With this introduction, there are many advantages to study MBBS in China. Here are a few things to consider.
                    </p>
                    <p className="studyintxt">
                        Chinese medical universities offer the same educational standards as top Indian medical colleges and universities. The government is so invested in its medical sector that only a handful of students are admitted. But the payoff is tremendous! China offers a range of opportunities for Indian students, from direct admission in top-ranked medical schools to very cheap tuition fees. You may also take advantage of the government’s policy of changing the admission criterion in 2008, which has made it easier for Indian students to pursue MBBS. Nowadays, Chinese medical colleges are only accepting students with 60% or above.
                    </p>
                    <p className="studyintxt">
                        MBBS in China is a six-year course. Students will engage in a wide range of practical and theoretical work, including a year-long internship in top Chinese hospitals.
                    </p>
                </div>
            </div>
        </>
    )
}

export default China;