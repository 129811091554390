import React from "react";

const Philippines = () => {
    return (
        <>
            <div className="studymainim">
                <h1 className="mbbstitle">MBBS in Philippines</h1>
            </div>

            <div className="container">
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Study MBBS in Philippines
                    </h1>
                    <p className="studyintxt">
                        Are you planning to Study MBBS in Philippines? If yes, this article will give you an overview of the Philippines medical school system, fees, and advantages of enrolling in a program. The advantages of studying in the Philippines are outlined below. You can also download our free Android App to get counselling and admission guidance for MBBS Admission. There are many advantages of studying in the Philippines, but the most important benefit is that you’ll be able to choose from a variety of programs.
                    </p>
                </div>

                <div className="universities-section">
                    <h2 className="universities-title">
                        MBBS universities in Philippines
                    </h2>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="universities-left">
                                <ul style={{ listStyle: "none", paddingLeft: '0px' }}>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> UV Gullas College of Medicine</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> lyceum college of medicine</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Davao Medical School Foundation</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Our Lady of Fatima University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Central Philippine University</h6></li>
                                    <li><h6 className="universities-head"><span><img className="universities-icon" src="/assets/images/IMAGE–10.svg" alt="no-image" /></span> Manila Central University</h6></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img className="universitiesmain-img" src="/assets/images/295429.png" alt="no-image" />
                        </div>
                    </div>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        MBBS in Philippines fees
                    </h1>
                    <p className="studyintxt">
                        MBBS in Philippines is a great opportunity for those who want to pursue their medical degree in an English-speaking country. Because of its strong medical education system, the country offers internationally recognized courses taught in English. The program also provides students with a variety of options for their future. If you have decided to pursue a medical degree in the Philippines, here are some tips to make the process easier and more affordable. Here are a few benefits of studying MBBS in the Philippines:
                    </p>
                    <p className="studyintxt">
                        First and foremost, there is no entrance examination in the Philippines. Students can apply for the course with their NEET Qualification, and get admission to a recognized medical school without having to undergo an entrance examination. Also, the Philippines medical colleges and universities are recognized by MCI and WHO, which ensure quality schooling. Furthermore, there are no donation fees. Most of the medical colleges in the Philippines don’t ask for donations at the time of admission. Additionally, tuition fees in the Philippines are extremely affordable, and the cost of living in the country is also quite low.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        Advantages of studying MBBS in Philippines
                    </h1>
                    <p className="studyintxt">
                        The Philippines is an affordable destination for pursuing MBBS. Its medical education is considered world-class and is recognized by the World Health Organization. Its medical schools also offer students international medical exams. The cost of living in the Philippines is also very affordable, making it a perfect place to pursue your medical education. Some institutions even provide free accommodation. The cost of living in the Philippines is low enough for many students to make the decision to study in this country.
                    </p>
                    <p className="studyintxt">
                        One of the biggest advantages of studying in the Philippines is the fact that it is cheaper than studying in India. Compared to India, tuition fees in the Philippines are significantly lower. Students must have an excellent score to be admitted to the course. Most universities in the Philippines follow the practical-based medical education system. Most of them are partnered with hospitals in the Philippines, so students can have extensive clinical training and experience. This gives students an advantage when it comes to getting a job in the Philippines or in India.
                    </p>
                </div>
                <div className="studyinsection">
                    <h1 className="studyin-title">
                        MBBS in Philippines overview
                    </h1>
                    <p className="studyintxt">
                        The Philippines offers one of the most affordable, high-quality MBBS programs in Asia, as well as the option of studying the medical degree in English. As the third-largest English-speaking nation in the world, the Philippines is a popular choice among international students. Nearly 8000 international students study in medical schools and universities each year, with around half of them from India. The Philippines’ medical schools and universities are known for their quality and educational standards, and the country sends out thousands of doctors every year.
                    </p>
                    <p className="studyintxt">
                        The cost of an MBBS program in the Philippines varies according to the choice of university, but is comparable to the fees for a local management quota. The total cost of studying MBBS in the Philippines will depend on the choice of university and the course chosen. However, the fees are generally lower than in India. Despite the lower cost, it is still important to keep in mind that the Philippines’ medical school system is quite different from that of India, so it is imperative to choose a college wisely.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Philippines;